<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="searchList()">搜索</el-button>
			</div>
			<el-date-picker size="small" style="width: 230px" @change="searchList()"
				v-model="search.time"
				type="daterange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期">
			</el-date-picker>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="video_id" label="视频id"></el-table-column>
			<el-table-column prop="cover_url" label="视频封面">
				<template slot-scope="scope">
					<img style="width: 50px;" :src="scope.row.cover_url" alt="">
				</template>
			</el-table-column>
            <el-table-column prop="org_name" label="机构名称"></el-table-column>
            <el-table-column prop="ShortName" label="所在城市"></el-table-column>
            <el-table-column prop="play_pv_count" label="播放次数"></el-table-column>
            <el-table-column prop="duration" label="视频时长"></el-table-column>
			<el-table-column prop="complete_play_count" label="完播次数"></el-table-column>
			<el-table-column prop="complete_play_rate" label="完播率"></el-table-column>
            <el-table-column prop="share_count" label="视频直接分享次数"></el-table-column>
            <el-table-column prop="pay_order_rate" label="下单率"></el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import { overviewOfVideo } from '@/api/dataAnalysis.js';
	export default {
		name: "Video",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				// 列表
				searchGist:  [{
					value: 'video_id',
					label: '视频id'
				}, {
					value: 'org_name',
					label: '机构名'
				}],
				search: {
					search_gist: 'org_name',
					desp: '',
					time: '',
				},
				// 列表
				total: 0,
				limit: 20,
				currentPage: 1,
				list: [],
			}
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				overviewOfVideo({
					limit: this.limit,
					page: this.currentPage,
					video_id: this.search.search_gist == 'video_id' ? this.search.desp : null,
					org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
					start_time: this.$common.setSearchTime(this.search.time)[0],
					end_time: this.$common.setSearchTime(this.search.time)[1]
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err, 222)
				})
			},
			// 搜索
			searchList(){
				this.loading = true
				this.currentPage = 1
				this.getList()
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true
				this.currentPage = val;
				this.getList();
            },
		}
	};
</script>
